var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.total,"options":_vm.options,"loading":_vm.loading,"item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Pacientes")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v("Nuevo")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.login)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"doc_tipo_id","name":"Tipo Documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"autofocus":"","items":_vm.tiposDocumento,"item-value":"id","item-text":"descripcion","filled":"","dense":"","label":"Tipo Documento","required":"","error-messages":errors,"success":valid},model:{value:(_vm.item.doc_tipo_id),callback:function ($$v) {_vm.$set(_vm.item, "doc_tipo_id", $$v)},expression:"item.doc_tipo_id"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"doc_numero","name":"Número de Documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"label":"Número de Documento","filled":"","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.item.doc_numero),callback:function ($$v) {_vm.$set(_vm.item, "doc_numero", $$v)},expression:"item.doc_numero"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"apellidos","name":"Apellidos","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Apellidos","required":"","error-messages":errors,"success":valid},model:{value:(_vm.item.apellidos),callback:function ($$v) {_vm.$set(_vm.item, "apellidos", $$v)},expression:"item.apellidos"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"nombres","name":"Nombres","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Nombres","required":"","error-messages":errors,"success":valid},model:{value:(_vm.item.nombres),callback:function ($$v) {_vm.$set(_vm.item, "nombres", $$v)},expression:"item.nombres"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{ref:"datepickerFechaNacimiento",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('ValidationProvider',{attrs:{"name":"Fecha Nacimiento","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{ref:"fechaNacimiento",attrs:{"type":"date","filled":"","dense":"","label":"Fecha Nacimiento","prepend-icon":"mdi-calendar","error-messages":errors,"success":valid},on:{"click:prepend":_vm.openDatepickerFechaNacimiento,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.openDatepickerFechaNacimiento.apply(null, arguments)}},model:{value:(_vm.item.fecha_nacimiento),callback:function ($$v) {_vm.$set(_vm.item, "fecha_nacimiento", $$v)},expression:"item.fecha_nacimiento"}})]}}],null,true)})]}}],null,true),model:{value:(_vm.datepickerFechaNacimiento),callback:function ($$v) {_vm.datepickerFechaNacimiento=$$v},expression:"datepickerFechaNacimiento"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"change":_vm.closeDatepickerFechaNacimiento},model:{value:(_vm.item.fecha_nacimiento),callback:function ($$v) {_vm.$set(_vm.item, "fecha_nacimiento", $$v)},expression:"item.fecha_nacimiento"}})],1)],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"email","name":"E-mail","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"email","filled":"","label":"E-mail","error-messages":errors,"success":valid},model:{value:(_vm.item.email),callback:function ($$v) {_vm.$set(_vm.item, "email", $$v)},expression:"item.email"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"telefono_movil","name":"Teléfono Móvil","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Teléfono Móvil","error-messages":errors,"success":valid},model:{value:(_vm.item.telefono_movil),callback:function ($$v) {_vm.$set(_vm.item, "telefono_movil", $$v)},expression:"item.telefono_movil"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"domicilio","name":"Domicilio","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Domicilio","error-messages":errors,"success":valid},model:{value:(_vm.item.domicilio),callback:function ($$v) {_vm.$set(_vm.item, "domicilio", $$v)},expression:"item.domicilio"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"localidad","name":"Localidad","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"filled":"","label":"Localidad","error-messages":errors,"success":valid},model:{value:(_vm.item.localidad),callback:function ($$v) {_vm.$set(_vm.item, "localidad", $$v)},expression:"item.localidad"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"obra_social_id","name":"Obra Social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.obrasSociales,"item-value":"id","item-text":"nombre","filled":"","label":"Obra Social","required":"","error-messages":errors,"success":valid},model:{value:(_vm.item.obra_social_id),callback:function ($$v) {_vm.$set(_vm.item, "obra_social_id", $$v)},expression:"item.obra_social_id"}})]}}],null,true)})],1)],1),_c('v-row')],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.saving},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"type":"submit","color":"primary","dark":"","loading":_vm.saving},on:{"click":function($event){$event.preventDefault();return passes(_vm.save)}}},[_vm._v("Guardar")])],1)],1)],1)]}}])})],1)],1)]},proxy:true},{key:"item.doc_numero",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.doc_tipo_descripcion) + " " + (item.doc_numero)))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }