<template>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="total"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      item-key="id"
    >

      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Pacientes</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>

          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>

          <v-dialog v-model="dialog" persistent max-width="500px">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
            </template>
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
              <v-form @submit.prevent="passes(login)">
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <ValidationProvider vid="doc_tipo_id" name="Tipo Documento" rules="required" v-slot="{ errors, valid }">
                            <v-select
                              autofocus
                              v-model="item.doc_tipo_id"
                              :items="tiposDocumento"
                              item-value="id"
                              item-text="descripcion"
                              filled
                              dense
                              label="Tipo Documento"
                              required
                              :error-messages="errors"
                              :success="valid"
                            ></v-select>
                          </ValidationProvider>
                        </v-col>
                        <v-col>
                          <ValidationProvider vid="doc_numero" name="Número de Documento" rules="required" v-slot="{ errors, valid }">
                            <v-text-field
                              v-model="item.doc_numero"
                              label="Número de Documento"
                              filled
                              dense
                              required
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <ValidationProvider vid="apellidos" name="Apellidos" rules="required" v-slot="{ errors, valid }">
                            <v-text-field
                              filled
                              v-model="item.apellidos"
                              label="Apellidos"
                              required
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col>
                          <ValidationProvider vid="nombres" name="Nombres" rules="required" v-slot="{ errors, valid }">
                            <v-text-field
                              filled
                              v-model="item.nombres"
                              label="Nombres"
                              required
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <v-menu
                            ref="datepickerFechaNacimiento"
                            v-model="datepickerFechaNacimiento"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ /*on*/ }">
                              <ValidationProvider name="Fecha Nacimiento" rules="" v-slot="{ errors, valid }">
                                <v-text-field
                                  type="date"
                                  ref="fechaNacimiento"
                                  v-model="item.fecha_nacimiento"
                                  filled
                                  dense
                                  label="Fecha Nacimiento"
                                  prepend-icon="mdi-calendar"
                                  :error-messages="errors"
                                  :success="valid"
                                  v-on:click:prepend="openDatepickerFechaNacimiento"
                                  v-on:keydown.enter.stop.prevent="openDatepickerFechaNacimiento"
                                ></v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              v-model="item.fecha_nacimiento"
                              scrollable
                              @change="closeDatepickerFechaNacimiento"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col>
                          <ValidationProvider vid="email" name="E-mail" rules="email" v-slot="{ errors, valid }">
                            <v-text-field
                              type="email"
                              filled
                              v-model="item.email"
                              label="E-mail"
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!--v-col>
                          <ValidationProvider vid="telefono_fijo" name="Teléfono Fijo" rules="" v-slot="{ errors, valid }">
                            <v-text-field
                              filled
                              v-model="item.telefono_fijo"
                              label="Teléfono Fijo"
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col-->
                        <v-col>
                          <ValidationProvider vid="telefono_movil" name="Teléfono Móvil" rules="" v-slot="{ errors, valid }">
                            <v-text-field
                              filled
                              v-model="item.telefono_movil"
                              label="Teléfono Móvil"
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col>
                          <ValidationProvider vid="domicilio" name="Domicilio" rules="" v-slot="{ errors, valid }">
                            <v-text-field
                              filled
                              v-model="item.domicilio"
                              label="Domicilio"
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col>
                          <ValidationProvider vid="localidad" name="Localidad" rules="" v-slot="{ errors, valid }">
                            <v-text-field
                              filled
                              v-model="item.localidad"
                              label="Localidad"
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col>
                          <ValidationProvider vid="obra_social_id" name="Obra Social" rules="required" v-slot="{ errors, valid }">
                            <v-select
                              v-model="item.obra_social_id"
                              :items="obrasSociales"
                              item-value="id"
                              item-text="nombre"
                              filled
                              label="Obra Social"
                              required
                              :error-messages="errors"
                              :success="valid"
                            ></v-select>
                          </ValidationProvider>
                        </v-col>
                      </v-row>

                      <v-row>
                        <!--v-col>
                          <ValidationProvider vid="pais" name="País" rules="" v-slot="{ errors, valid }">
                            <v-text-field
                              filled
                              v-model="item.pais"
                              label="País"
                              :error-messages="errors"
                              :success="valid"
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col-->
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text :disabled="saving" @click="close">Cancelar</v-btn>
                    <v-btn type="submit" @click.prevent="passes(save)" color="primary"
                        dark :loading="saving">Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </ValidationObserver>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.doc_numero="{ item }" class="text-right">
        {{ `${item.doc_tipo_descripcion} ${item.doc_numero}` }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"
import _ from 'lodash'
//import moment from 'moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    dialog: false,
    loading: false,
    saving: false,
    search: '',
    headers: [
      { text: 'Documento', value: 'doc_numero' },
      { text: 'Apellidos', value: 'apellidos' },
      { text: 'Nombres', value: 'nombres' },
      { text: 'Obra Social', value: 'obra_social_nombre' },
      { text: 'Acciones', value: 'action', align: 'right', sortable: false },
    ],
    items: [],
    total: 0,
    options: {},
    item: {
      doc_tipo_id: null,
      doc_numero: '',
      apellidos: '',
      nombres: '',
      fecha_nacimiento: null,
      telefono_fijo: '',
      telefono_movil: '',
      email: '',
      domicilio: '',
      localidad: '',
      provincia: '',
      pais: '',
      obra_social_id: null,
    },
    defaultItem: {
      doc_tipo_id: null,
      doc_numero: '',
      apellidos: '',
      nombres: '',
      fecha_nacimiento: null,
      telefono_fijo: '',
      telefono_movil: '',
      email: '',
      domicilio: '',
      localidad: '',
      provincia: '',
      pais: '',
      obra_social_id: null,
    },
    tiposDocumento: [],
    obrasSociales: [],
    datepickerFechaNacimiento: false,
  }),

  computed: {
    formTitle () {
      return (typeof this.item.id === 'undefined') ? 'Nuevo' : 'Editar'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },

    options: {
      handler () {
        this.query()
      },
      deep: true,
    },

    search: function (/*val*/) {
      this.debounceRefresh()
    },
  },

  methods: {
    debounceRefresh: _.debounce(function () {
      this.refresh()
    }, 1000),

    refresh: function () {
      this.options.page = 1
      this.query()
    },

    query: function () {
      this.loading = true;

      let filters = {
        filter: this.search,
        sortBy: this.options.sortBy[0] || null,
        descending: this.options.sortDesc[0] || null,
        rowsPerPage: this.options.itemsPerPage,
        page: this.options.page,
      };

      this.$http.get('pacientes', {params: filters})
      .then((response) => {
        this.items = response.data
        this.total = parseInt(response.headers['pager-total'])
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false;
      })
    },

    save: function () {
      this.saving = true

      if (typeof this.item.id === 'undefined') {
        this.create()
      } else {
        this.update()
      }
    },

    create: function () {
      this.$http.post('pacientes', this.item)
        .then(() => {
          this.query()
          this.close()
          this.$eventHub.$emit('snackbar-message', 'El registro fue creado!')
        })
        .catch((error) => {
          switch (error.response.status) {
            case 401:
              break;
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    update: function () {
        this.$http.put('pacientes/'+this.item.id, this.item)
        .then(() => {
          this.query()
          this.close()
          this.$eventHub.$emit('snackbar-message', 'El registro fue actualizado!')
        })
        .catch((error) => {
          switch (error.response.status) {
            case 422:
              this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario')

              this.$refs.obs.setErrors(error.response.data.errors);

              break;
            default:
              this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud')
          }
        })
        .finally(() => {
          this.saving = false
        })
    },

    editItem: function (item) {
      this.$http.get('pacientes/'+item.id)
        .then((response) => {
          this.item = response.data
          this.dialog = true
        })
        .catch((error) => {
          alert(error);
        })
    },

    deleteItem: function (item) {
      if ( confirm('¿Está seguro que quiere eliminar al paciente? Si confirma se van a eliminar también su usuario, historia clínica y turnos.') ) {
        this.$http.delete('pacientes/'+item.id)
          .then(() => {
            this.query();
          })
          .catch((error) => {
            switch (error.response.status) {
              case 422:
                this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')
                break;
              default:
                this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
            }
          })
      }
    },

    resetForm: function () {
      this.$refs.obs.reset()
      this.item = {...this.defaultItem}
    },

    close: function () {
      this.resetForm()
      this.dialog = false
    },

    fetchTiposDocumentos: function () {
      this.$http.get('tipos-documento')
      .then((response) => {
        this.tiposDocumento = response.data
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },

    fetchObrasSociales: function () {
      this.$http.get('obras-sociales')
      .then((response) => {
        this.obrasSociales = response.data
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },

    openDatepickerFechaNacimiento: function () {
      this.datepickerFechaNacimiento = true
    },

    closeDatepickerFechaNacimiento: function () {
      this.datepickerFechaNacimiento = false

      setTimeout(() => {
        this.$refs.fechaNacimiento.$el.querySelector('input').focus()
      }, 10);
    },
  },

  created () {
    this.fetchTiposDocumentos()
    this.fetchObrasSociales()
  }
};
</script>
